import React from "react"
import Layout from "../templates/layout"
import Topography from '../components/topography-section'
import Hero from '../components/hero'
import ContactForm from '../components/contact-form'
import Container from '../components/container'


const ContactUs = ({ data }) => (
  <Layout
    title="Contact Us | Loveless Outdoor Adventures"
    description="Have questions about bowfishing, catfishing, or duck hunting on Kentucky Lake? Contact us today and we'll be happy to answer any questions you have on our services."
  >
    <Hero title="contact us" tagline="have a question?" />
    <Topography>
      <Container>
        <ContactForm formName="Contact Us Page" buttonName="contact us"/>
      </Container>
    </Topography>
  </Layout>

)


export default ContactUs
 